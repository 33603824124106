import WorkIcon from "@mui/icons-material/Work";
import {Box, Typography} from "@mui/material";
import React from "react";

const Header = () => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100px' }}>
            <WorkIcon fontSize={'large'} style={{marginRight: 10, color: '#1B1A4F'}}/>
            <Typography variant="h2" style={{textAlign: 'center', color: '#1B1A4F'}}>unpostedjobs</Typography>
        </Box>
    );
}

export default Header;