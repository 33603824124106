import React, {useState} from 'react';
import './App.css';

import HomePage from "./pages/HomePage";
import {Route, Routes, BrowserRouter} from "react-router-dom";
import {UserType} from "./types";
import {AuthContext} from "./context/AuthContext";
import {AuthRoute} from "./components/AuthRoute";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import ProfilePage from "./pages/ProfilePage";

function App() {
    const userFromStorage = sessionStorage.getItem("user");
    const [user, setUser] = useState<UserType | null>(
        userFromStorage ? JSON.parse(userFromStorage) : null
    );
    const [loading] = useState(true); // Initialize loading state


    return (
      <div>
        <BrowserRouter>
          <AuthContext.Provider value={{ user, setUser, loading }}>
            <Routes>
                <Route path="/" element={
                    <AuthRoute>
                        <HomePage />
                    </AuthRoute>
                } />
                <Route path="/profile" element={
                    <AuthRoute>
                        <ProfilePage />
                    </AuthRoute>
                } />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignupPage />} />
            </Routes>
          </AuthContext.Provider>
        </BrowserRouter>
      </div>
    );
}

export default App;
