import AuthForm from "../components/AuthForm";

const SignupPage = () => {
    return (
        <>
            <AuthForm
                mode="register"
                title="Create a new account"
                buttonText="Sign up"
                linkUrl="/login"
                linkText="Login"
            />
        </>
    );
};

export default SignupPage;