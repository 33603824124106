import {initializeApp} from 'firebase/app'
import {
    createUserWithEmailAndPassword,
    EmailAuthProvider,
    getAuth,
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signOut,
    User
} from 'firebase/auth'
import config from './config';
import { collection, addDoc, getFirestore } from 'firebase/firestore';

const firebaseApp = initializeApp(config);

const firestore = getFirestore(firebaseApp);

export const Providers = {
    email: new EmailAuthProvider(),
    google: new GoogleAuthProvider(),};

export const auth = getAuth(firebaseApp);
export const db = getFirestore();

export const logInWithEmailAndPassword = async (
    email: string,
    password: string
): Promise<User | null> => {
    try {
        const res = await signInWithEmailAndPassword(auth, email, password);
        return res.user;
    } catch (err) {
        if (err instanceof Error) {
            // Handle authentication-specific errors gracefully
            console.error(err.message);
            alert(err.message);
        } else {
            console.error("Unexpected error", err);
        }
        return null; // Return null in case of error
    }
};

export const registerWithEmailAndPassword = async (
    name: string,
    email: string,
    password: string
): Promise<User | null> => {
    try {
        const res = await createUserWithEmailAndPassword(auth, email, password);

        return res.user;
    } catch (err) {
        if (err instanceof Error) {
            console.error(err.message);
            alert(err.message);
        } else {
            console.error("Unexpected error", err);
        }
        return null;
    }
}

export const logoutFirebase = async () => {
    try {
        return await signOut(auth)
    } catch (err) {
        console.error("Error signing out", err);
    }
};

export const insertClickedJob = async (jobID: string, userID: string) => {
    // User indicated they applied for the job, record the event in Firestore
    await addDoc(collection(firestore, "clickedJobs"), {
        userID: userID, // Replace with actual user ID
        jobID: jobID,
        timestamp: new Date(),
    });
}