import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

interface AuthRouteProps {
    children: React.ReactNode;
}

const AuthRoute = ({ children }: AuthRouteProps) => {
    const { user } = useContext(AuthContext);

    if (!user) {
        return <Navigate to="/login" replace />;
    }

    return <>{children}</>;
};

export { AuthRoute };