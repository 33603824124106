import React, { useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Button, TextField, Typography} from '@mui/material';
import {db} from "../config/firebase";
import {doc, getDoc} from "@firebase/firestore";
import { setDoc } from 'firebase/firestore';
import {useUser} from "../hooks/useUser";


const ProfilePage = () => {
    const navigate = useNavigate();
    
    const { user, loading } = useUser()
    const [userId] = useState(user?.userId || '');
    const [email, setEmail] = useState(user?.email || '');
    const [resumeText, setResumeText] = useState('');

    useEffect(() => {
        if (!loading && user) {
            const fetchResumeText = async () => {
                console.log("Fetching resume text for user:", user);
                try {
                    const docRef = doc(db, "users", user.userId);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        console.log("Document data:", docSnap.data());
                        // Set resume text if it exists in the document
                        setResumeText(docSnap.data().resumeText || ''); // Assuming the field is called resumeText
                    } else {
                        console.log("No such document!");
                    }
                } catch (error) {
                    console.error("Error fetching document:", error);
                }
            };
            fetchResumeText();
        }
    }, [loading, user]); // Rerun this effect if `loading` or `user` state changes


    const handleEmailChange = (event: { target: { value: React.SetStateAction<string>; }; }) => setEmail(event.target.value);

    const handleSave = async () => {
        if (user && user.userId) {
            const docRef = doc(db, "users", user.userId);
            try {
                await setDoc(docRef, { resumeText: resumeText }, { merge: true });
                console.log("Resume saved successfully!");
                // Optionally, show a success message to the user
            } catch (error) {
                console.error("Error saving resume: ", error);
                // Optionally, show an error message to the user
            }
        } else {
            console.log("No user ID found, unable to save.");
            // Optionally, handle the case where there is no user logged in or userId is missing
        }
    };

    const handleCancel = () => {
        setEmail(user?.email || '');
        navigate('/');
    };

    const handleBack = () => {
        navigate('/');
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh', // Full screen height
                    gap: 2,
                }}
            >
            <Typography>Profile Page</Typography>
            <TextField
                label="User ID"
                variant="outlined"
                value={userId}
                disabled
                margin="normal"
                sx={{ width: 350 }}
            />
            <TextField
                label="Email"
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                margin="normal"
                sx={{ width: 350 }}
            />
            <TextField
                label="Resume Text"
                variant="outlined"
                multiline
                rows={4} // Adjust based on how large you want this field to be
                value={resumeText}
                onChange={(event) => setResumeText(event.target.value)}
                margin="normal"
                sx={{ width: 600 }}
            />
            <Button variant="contained" onClick={handleSave} style={{ width: 350 }}>
                Save
            </Button>
            <Button variant="outlined" onClick={handleCancel} style={{ width: 350 }}>
                Cancel
            </Button>
            <Button variant="contained" onClick={handleBack} style={{ width: 350 }}>
                Back to Home
            </Button>
            </Box>
        </>
    );
};

export default ProfilePage;