import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { UserType } from "../types";
import {useSessionStorage} from "./useSessionStorage";
import { auth } from "../config/firebase";

export const useUser = () => {
    const { user, setUser } = useContext(AuthContext);
    const [loading, setLoading] = useState(true); // Add a loading state
    const { setItemSession } = useSessionStorage();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
            if (firebaseUser) {
                // Convert Firebase user to your UserType, if necessary
                const user: UserType = {
                    userId: firebaseUser.uid,
                    name: firebaseUser.displayName,
                    email: firebaseUser.email,
                };
                setUser(user);
                setItemSession("user", JSON.stringify(user));
            } else {
                setUser(null);
                setItemSession("user", "");
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const addUser = (user: UserType) => {
        setUser(user);
        if (sessionStorage.getItem("user")) {
            setItemSession("user", "");
        } else {
            setItemSession("user", JSON.stringify(user));
        }
    };

    const removeUser = () => {
        setUser(null);
        if (sessionStorage.getItem("user")) {
            setItemSession("user", "");
        }
    };

    return { user, addUser, removeUser, loading};
};