import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import {
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
} from '../config/firebase';
import { FaUserPlus } from 'react-icons/fa';
import { Button, TextField, Typography, Container, Box } from '@mui/material';
import Header from './Header';

interface AuthFormProps {
    mode: 'login' | 'register';
    title: string;
    buttonText: string;
    linkUrl: string;
    linkText: string;
}

const initialFormState = {
    email: '',
    password: '',
    name: '',
};

const AuthForm = ({ mode, title, buttonText, linkUrl, linkText }: AuthFormProps) => {
    const [formState, setFormState] = useState(initialFormState);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Prevent default form submission behavior

        setIsSubmitting(true); // Indicate the form is being submitted

        try {
            let user = null;
            if (mode === 'login') {
                // Attempt to log in
                user = await logInWithEmailAndPassword(formState.email, formState.password);
            } else if (mode === 'register') {
                // Attempt to register
                user = await registerWithEmailAndPassword(formState.name, formState.email, formState.password);
            }

            // Assuming user is returned upon successful authentication
            if (user) {
                const userData = {
                    userId: user.uid || "",
                    name: user.displayName || formState.name, // For registration, use the name from formState
                    email: user.email || "",
                };

                login(userData); // Log in the user in your app context or perform relevant action
                navigate("/"); // Navigate to the homepage or dashboard
            } else {
                // Handle null user scenario (e.g., authentication failed)
                alert("Authentication failed. Please try again.");
            }
        } catch (err) {
            // Handle errors (e.g., wrong credentials or network issues)
            console.error("Authentication error:", err);
            alert("An error occurred during authentication. Please check your details and try again.");
        } finally {
            setIsSubmitting(false); // Reset submission state regardless of outcome
        }
    };

    return (
        <>
            <Header />
            <Container maxWidth="sm">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        {title}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={formState.email}
                            onChange={(event) =>
                                setFormState((prevState) => ({
                                    ...prevState,
                                    email: event.target.value,
                                }))
                            }
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={formState.password}
                            onChange={(event) =>
                                setFormState((prevState) => ({
                                    ...prevState,
                                    password: event.target.value,
                                }))
                            }
                        />
                        {mode === 'register' && (
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Full Name"
                                name="name"
                                autoComplete="name"
                                value={formState.name}
                                onChange={(event) =>
                                    setFormState((prevState) => ({
                                        ...prevState,
                                        name: event.target.value,
                                    }))
                                }
                            />
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={isSubmitting}
                        >
                            {buttonText}
                        </Button>
                        <Link to={linkUrl} style={{ textDecoration: 'none' }}>
                            <Button variant="text" startIcon={<FaUserPlus />} sx={{ mt: 3, mb: 2 }}>
                                {linkText}
                            </Button>
                        </Link>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default AuthForm;
