import React from "react";
import AuthForm from "../components/AuthForm";

const LoginPage = () => {
    return (
        <>
             <AuthForm
                    mode="login"
                    title="Sign In"
                    buttonText="Login"
                    linkUrl="/signup"
                    linkText="signup"
                />
        </>
    );
};

export default LoginPage;