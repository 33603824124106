const config = {
    apiKey: "AIzaSyBaBgPw0JWheo-zC2CS2B7LMg9McqAIgKQ",
    authDomain: "unpostedjobs-25c69.firebaseapp.com",
    projectId: "unpostedjobs-25c69",
    storageBucket: "unpostedjobs-25c69.appspot.com",
    messagingSenderId: "1024274638419",
    appId: "1:1024274638419:web:9bce88e65a1dde5dab08a6",
    measurementId: "G-DDVB5W12S8"
};

export default config;
