import { createContext } from "react";
import { UserType } from "../types";

interface IAuthContext {
    user: UserType | null;
    setUser: (user: UserType | null) => void;
    loading: boolean;
}

export const AuthContext = createContext<IAuthContext>({
    user: null,
    setUser: () => {},
    loading: true,
});

